<template>
  <div>
    <BaseTable
      ref="sale-invoices-reports-table"
      :resourceStore="'saleInvoicesReports'"
      :columns="columns"
      :resource="
        $data.$constants.RESOURCES.RESOURCE_SALE_INVOICE_REPORTS_PENDING_PAYMENT
      "
      :loading="loading"
      :filters="filters"
      :totals-configuration="totalsConfiguration"
      @data-loaded="$emit('data-loaded', $event)"
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearListFilters"
      @row-clicked="
        $router.push({ name: 'viewSaleInvoice', params: { id: $event.id } })
      "
    >
      <template #cell(invoice_date)="data">
        <div>{{ data.item.invoice_date | formatDate }}</div>
      </template>
      <template #cell(invoice_due_date)="data">
        <div>{{ data.item.invoice_due_date | formatDate }}</div>
      </template>
      <template #cell(client)="data">
        <div>{{ data.item.client.name }}</div>
      </template>
      <template #cell(status)="data">
        <StatusBadge
          :status="data.item.status.name"
          :text="
            data.item.status.name === 'Cobro parcial'
              ? `Cobro parcial ${
                  data.item.percent !== 0 && data.item.percent !== 100
                    ? `${data.item.percent} % `
                    : ''
                }`
              : null
          "
        />
        <StatusBadge
          v-if="
            data.item.received_payment_document &&
            data.item.received_payment_document === 1
          "
          class="mt-1"
          :status="'Rec. Doc. Cobro'"
          :text="'Rec. Doc. Cobro'"
          :variant="'light-success'"
        />
      </template>
      <template #cell(total_pending_collect)="data">
        <div>{{ data.item.total_pending_collect | numberToLocalString }} €</div>
      </template>
      <template #cell(total_retention)="data">
        <div>{{ data.item.total_retention | numberToLocalString }} €</div>
        <div v-show="data.item.invoice_due_date" class="font-small text-light">
          vence {{ data.item.invoice_due_date | formatDate }}
        </div>
      </template>
      <template #cell(total_gross)="data">
        <div>{{ data.item.total_gross | numberToLocalString }} €</div>
      </template>
      <template #cell(total_invoice)="data">
        <div>{{ data.item.total_invoice | numberToLocalString }} €</div>
      </template>
      <template #cell(total_liquid)="data">
        <div>{{ data.item.total_liquid | numberToLocalString }} €</div>
      </template>
    </BaseTable>
    <SaleInvoicesReportsListFilters 
      ref="sale-invoices-reports-filters"
      v-model="filtersVisible"
      @filters-submit="$store.commit('saleInvoicesReports/setFilters', $event)" 
    />
  </div>
</template>

<script>
import BaseTable from "@/components/ui/table/BaseTable.vue"
import DateTimeService from '@/shared/services/date-time-service'
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import SaleInvoicesReportsListFilters from "@/components/sale-invoices-reports/filters/SaleInvoicesReportsListFilters.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BaseTable,
    StatusBadge,
    SaleInvoicesReportsListFilters
  },
  data() {
    return {
      loading: false,
      filtersVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      filters: "saleInvoicesReports/getFilters",
    }),
    totalsConfiguration() {
      return [
        { colspan: 5 },
        { label: 'TOTAL PENDIENTE', key: 'total_pending_collect', unit: '€' },
        { label: 'TOTAL BRUTO', key: 'total_gross', unit: '€' },
        { label: 'TOTAL RETENCIÓN', key: 'total_retention', unit: '€' },
        { label: 'TOTAL LÍQUIDO', key: 'total_liquid', unit: '€' },
        { label: 'TOTAL', key: 'total_invoice', unit: '€' },
        // { colspan: 1 },
      ]
    },
    columns() {
      let result = [
        {
          label: "NÚMERO",
          key: "code",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { "min-width": "100px" },
        },
        {
          label: "FECHA",
          key: "invoice_date",
          sortable: true,
        },
        {
          label: "VENCIMIENTO",
          key: "invoice_due_date",
          sortable: true,
        },
        {
          label: "CLIENTE",
          key: "client",
          sortable: true,
        },
        {
          label: "ESTADO",
          key: "status",
          sortable: true,
        },
        {
          label: "T. PENDIENTE",
          key: "total_pending_collect",
          sortable: true,
          thClass: "text-right",
          tdClass: "text-right",
          thStyle: { "min-width": "120px" },
        },
        {
          label: "T. BRUTO",
          key: "total_gross",
          sortable: true,
          thClass: "text-right",
          tdClass: "text-right",
          thStyle: { "min-width": "120px" },
        },
        {
          label: "RETENCIÓN",
          key: "total_retention",
          sortable: true,
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          label: "LÍQUIDO A PERCIBIR",
          key: "total_liquid",
          sortable: true,
          thClass: "text-right",
          tdClass: "text-right",
          thStyle: { "min-width": "175px" },
        },
        {
          label: "T. FACTURA",
          key: "total_invoice",
          sortable: true,
          thClass: "text-right",
          tdClass: "text-right",
          thStyle: { "min-width": "120px" },
        },
      ];

      return result;
    },
  },
  methods: {
    getDueDateDaysText(dueDate) {
      if (!dueDate) {
        return ''
      }

      const days = DateTimeService.getDifferenceInDays(new Date(), new Date(dueDate))
      return this.isDueDateExpired(dueDate) ? `Vencida ${days} dias` : `Vence en ${days} dias`
    },
    isDueDateExpired(dueDate) {
      if (!dueDate) {
        return false
      }

      return new Date() > new Date(dueDate)
    },
    handleClearListFilters() {
      this.$store.commit("saleInvoicesReports/setFilters", {});
      this.$refs["sale-invoices-reports-filters"].clearFilters();
    },
  }
};
</script>
