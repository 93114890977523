var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{ref:"sale-invoices-reports-table",attrs:{"resourceStore":'saleInvoicesReports',"columns":_vm.columns,"resource":_vm.$data.$constants.RESOURCES.RESOURCE_SALE_INVOICE_REPORTS_PENDING_PAYMENT,"loading":_vm.loading,"filters":_vm.filters,"totals-configuration":_vm.totalsConfiguration},on:{"data-loaded":function($event){return _vm.$emit('data-loaded', $event)},"filter-button-click":function($event){_vm.filtersVisible = !_vm.filtersVisible},"on-clear-active-filters":_vm.handleClearListFilters,"row-clicked":function($event){return _vm.$router.push({ name: 'viewSaleInvoice', params: { id: $event.id } })}},scopedSlots:_vm._u([{key:"cell(invoice_date)",fn:function(data){return [_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(data.item.invoice_date)))])]}},{key:"cell(invoice_due_date)",fn:function(data){return [_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(data.item.invoice_due_date)))])]}},{key:"cell(client)",fn:function(data){return [_c('div',[_vm._v(_vm._s(data.item.client.name))])]}},{key:"cell(status)",fn:function(data){return [_c('StatusBadge',{attrs:{"status":data.item.status.name,"text":data.item.status.name === 'Cobro parcial'
            ? ("Cobro parcial " + (data.item.percent !== 0 && data.item.percent !== 100
                  ? ((data.item.percent) + " % ")
                  : ''))
            : null}}),(
          data.item.received_payment_document &&
          data.item.received_payment_document === 1
        )?_c('StatusBadge',{staticClass:"mt-1",attrs:{"status":'Rec. Doc. Cobro',"text":'Rec. Doc. Cobro',"variant":'light-success'}}):_vm._e()]}},{key:"cell(total_pending_collect)",fn:function(data){return [_c('div',[_vm._v(_vm._s(_vm._f("numberToLocalString")(data.item.total_pending_collect))+" €")])]}},{key:"cell(total_retention)",fn:function(data){return [_c('div',[_vm._v(_vm._s(_vm._f("numberToLocalString")(data.item.total_retention))+" €")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(data.item.invoice_due_date),expression:"data.item.invoice_due_date"}],staticClass:"font-small text-light"},[_vm._v(" vence "+_vm._s(_vm._f("formatDate")(data.item.invoice_due_date))+" ")])]}},{key:"cell(total_gross)",fn:function(data){return [_c('div',[_vm._v(_vm._s(_vm._f("numberToLocalString")(data.item.total_gross))+" €")])]}},{key:"cell(total_invoice)",fn:function(data){return [_c('div',[_vm._v(_vm._s(_vm._f("numberToLocalString")(data.item.total_invoice))+" €")])]}},{key:"cell(total_liquid)",fn:function(data){return [_c('div',[_vm._v(_vm._s(_vm._f("numberToLocalString")(data.item.total_liquid))+" €")])]}}])}),_c('SaleInvoicesReportsListFilters',{ref:"sale-invoices-reports-filters",on:{"filters-submit":function($event){return _vm.$store.commit('saleInvoicesReports/setFilters', $event)}},model:{value:(_vm.filtersVisible),callback:function ($$v) {_vm.filtersVisible=$$v},expression:"filtersVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }