<template>
  <BaseSelect
    :id="id"
    :value="value"
    :options="['F', 'R']"
    @input="$emit('input', $event)"
  />
</template>

<script>
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'SaleInvoicesCategorySelect',
  components: { BaseSelect },
  props: {
    value: {
      type: [Object, String, Number],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped>
</style>
