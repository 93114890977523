<template>
   <BaseCard
    :footer-visible="false"
    :caption-visible="false"
  >
    <template #title>
      <span class="h3 font-weight-bold mb-0 text-uppercase">
        Informe facturas pendientes de cobro
      </span>
    </template>
    <SalesInvoicesReportsTable />
  </BaseCard>
</template>


<script>
import SalesInvoicesReportsTable from '@/components/sale-invoices-reports/table/SaleInvoicesReportsTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: { BaseCard, SalesInvoicesReportsTable },
}
</script>
