<template>
  <b-form
    @submit.prevent="$emit('submit', model)"
    @keydown.enter="$emit('submit', model)"
  >
    <b-row>
      <b-col sm="12">
        <b-form-group>
          <label>
            Fecha entre
          </label>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.invoice_date_min"
                class="margin-right-datepicker"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Desde"
                :label-help="null"
                :max="model.invoice_date_max"
                @input="update('invoice_date_min', $event)"
                start-weekday="1"
              />
            </b-col>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.invoice_date_max"
                class="margin-left-datepicker"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Hasta"
                :label-help="null"
                :min="model.invoice_date_min"
                @input="update('invoice_date_max', $event)"
                start-weekday="1"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col sm="12">
        <b-form-group>
          <label>
            Fecha vencimiento entre
          </label>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.invoice_due_date_min"
                class="margin-right-datepicker"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Desde"
                :label-help="null"
                :max="model.invoice_due_date_max"
                @input="update('invoice_due_date_min', $event)"
                start-weekday="1"
              />
            </b-col>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.invoice_due_date_max"
                class="margin-left-datepicker"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Hasta"
                :label-help="null"
                :min="model.invoice_due_date_min"
                @input="update('invoice_due_date_max', $event)"
                start-weekday="1"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Tipo"
          label-for="sale-invoice-category"
        >
          <SaleInvoicesCategorySelect
            id="sale-invoice-category"
            :value="model.sale_invoice_category"
            @input="update('sale_invoice_category', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Serie"
          label-for="sale-invoice-serie"
        >
          <b-form-input
            id="sale-invoice-serie"
            :value="model.serie"
            name="sale-invoice-serie"
            placeholder="Serie"
            @input="update('serie', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Número"
          label-for="sale-invoice-code"
        >
          <b-form-input
            id="sale-invoice-code"
            :value="model.code"
            name="sale-invoice-code"
            placeholder="Número"
            @input="update('code', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="División"
          label-for="sale-invoice-division"
        >
          <BaseSelect
            id="sale-invoice-division"
            :value="model.division"
            :resource="$data.$constants.RESOURCES.RESOURCE_DIVISIONS"
            http-method="get"
            @input="update('division', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Cliente"
          label-for="sale-invoice-client"
        >
          <BaseSelect
            id="sale-invoice-client"
            :value="model.client"
            :resource="$data.$constants.RESOURCES.RESOURCE_CLIENTS"
            @input="update('client', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Job"
          label-for="sale-invoice-job"
        >
          <BaseSelect
            id="sale-invoice-job"
            :resource="$data.$constants.RESOURCES.RESOURCE_JOBS"
            :value="model.job"
            @input="update('job', $event)"
          />
        </b-form-group>
      </b-col>   
      <b-col sm="12">
        <b-form-group
          label="Recepción de documento de cobro"
          label-for="sale-invoice-received-payment-document"
        >
          <BaseSelect
            id="sale-invoice-received-payment-document"
            :value="model.received_payment_document"
            :options="[{ name: 'si', value: 1 }, { name: 'no', value: 0 }]"
            label="name"
            :reduce="item => item.value"
            @input="update('received_payment_document', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import { SALE_INVOICES_STATUS_TYPE } from '@/api/status-api'
import SaleInvoicesCategorySelect from '@/components/sale-invoices/select/SaleInvoicesCategorySelect.vue'
import StatusSelect from '@/components/status/select/StatusSelect.vue'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'SaleInvoicesListFiltersForm',
  components: {
    BaseSelect,
    StatusSelect,
    SaleInvoicesCategorySelect,
  },
  mixins: [FormMixin],
  data() {
    return {
      statusType: SALE_INVOICES_STATUS_TYPE,
    }
  },
}
</script>

<style scoped>
.margin-left-datepicker {
  margin-left: 5px;
}
.margin-right-datepicker {
  margin-right: 5px;
}
</style>
